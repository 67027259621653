"use client";

import { useTheme } from "next-themes";
import { ThemeIcons } from "@/components/icons/icons";
import { Button } from "@/components/ui/button";
export function ThemeToggle() {
  const {
    setTheme,
    theme
  } = useTheme();
  return <Button variant="outline" size="icon" onClick={() => setTheme(theme === "light" ? "dark" : "light")} data-sentry-element="Button" data-sentry-component="ThemeToggle" data-sentry-source-file="theme-toggle.tsx">
      <ThemeIcons.LightMode className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" data-sentry-element="unknown" data-sentry-source-file="theme-toggle.tsx" />
      <ThemeIcons.DarkMode className="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" data-sentry-element="unknown" data-sentry-source-file="theme-toggle.tsx" />
      <span className="sr-only">Toggle theme</span>
    </Button>;
}