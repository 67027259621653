import { useState } from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
export function DemoAccessGate({
  onCorrectPassword
}: {
  onCorrectPassword: () => void;
}) {
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const response = await fetch("/api/demo-access", {
      method: "POST",
      body: JSON.stringify({
        password
      })
    });
    const data = await response.json();
    if (data.success) {
      onCorrectPassword();
      setError(false);
    } else {
      setError(true);
    }
  };
  return <div className="flex min-h-screen items-center justify-center bg-gray-50 dark:bg-gray-900" data-sentry-component="DemoAccessGate" data-sentry-source-file="DemoAccessGate.tsx">
      <div className="w-full max-w-md space-y-8 p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg">
        {/* Header section */}
        <div>
          <h2 className="text-center text-3xl font-bold text-gray-900 dark:text-gray-100">Demo Access</h2>
          <p className="mt-2 text-center text-sm text-gray-600 dark:text-gray-400">Please enter the demo password to continue</p>
        </div>
        {/* Password input form */}
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <div className="space-y-4">
            <Input type="password" value={password} onChange={e => setPassword(e.target.value)} placeholder="Enter password" className="w-full" aria-label="Demo password input" data-sentry-element="Input" data-sentry-source-file="DemoAccessGate.tsx" />
            {/* Conditional error message display */}
            {error && <p className="text-sm text-red-500">Incorrect password. Please try again.</p>}
            <Button type="submit" className="w-full" data-sentry-element="Button" data-sentry-source-file="DemoAccessGate.tsx">
              Access Demo
            </Button>
          </div>
        </form>
      </div>
    </div>;
}