import { useInternalToolbarStore } from "./store";
import { Button } from "@/components/ui/button";
import { Eye, EyeOff } from "lucide-react";
const ReplayAnalysisToggleButton = () => {
  const {
    showAnalyzeReplayButton,
    setShowAnalyzeReplayButton
  } = useInternalToolbarStore();
  const handleToggle = () => {
    setShowAnalyzeReplayButton(!showAnalyzeReplayButton);
  };
  return <Button variant="ghost" size="sm" onClick={handleToggle} className="flex items-center gap-2 text-white hover:text-white hover:bg-gray-700 border border-white rounded-sm" data-sentry-element="Button" data-sentry-component="ReplayAnalysisToggleButton" data-sentry-source-file="replayAnalysisToggleButton.tsx">
      {showAnalyzeReplayButton ? <>
          <EyeOff className="h-4 w-4" /> Hide Analyze Button
        </> : <>
          <Eye className="h-4 w-4" /> Show Analyze Button  
        </>}
    </Button>;
};
export default ReplayAnalysisToggleButton;