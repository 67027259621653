import(/* webpackMode: "eager", webpackExports: ["ClientClerkProvider"] */ "/vercel/path0/debugger-app/node_modules/@clerk/nextjs/dist/esm/app-router/client/ClerkProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["AuthenticateWithRedirectCallback","ClerkLoaded","ClerkLoading","MultisessionAppSupport","RedirectToCreateOrganization","RedirectToOrganizationProfile","RedirectToSignIn","RedirectToSignUp","RedirectToUserProfile"] */ "/vercel/path0/debugger-app/node_modules/@clerk/nextjs/dist/esm/client-boundary/controlComponents.js");
;
import(/* webpackMode: "eager", webpackExports: ["EmailLinkErrorCode","MagicLinkErrorCode","WithClerk","WithSession","WithUser","isClerkAPIResponseError","isEmailLinkError","isKnownError","isMagicLinkError","isMetamaskError","useAuth","useClerk","useEmailLink","useMagicLink","useOrganization","useOrganizationList","useOrganizations","useSession","useSessionList","useSignIn","useSignUp","useUser","withClerk","withSession","withUser"] */ "/vercel/path0/debugger-app/node_modules/@clerk/nextjs/dist/esm/client-boundary/hooks.js");
;
import(/* webpackMode: "eager", webpackExports: ["CreateOrganization","GoogleOneTap","OrganizationList","OrganizationProfile","OrganizationSwitcher","SignIn","SignInButton","SignInWithMetamaskButton","SignOutButton","SignUp","SignUpButton","UserButton","UserProfile"] */ "/vercel/path0/debugger-app/node_modules/@clerk/nextjs/dist/esm/client-boundary/uiComponents.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/debugger-app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/debugger-app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/debugger-app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/debugger-app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["CommandMenu"] */ "/vercel/path0/debugger-app/src/app/CommandMenu.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/debugger-app/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/debugger-app/src/app/internal-toolbar/internal-toolbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CSPostHogProvider"] */ "/vercel/path0/debugger-app/src/app/providers.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/debugger-app/src/components/ui/sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SidebarNavItems"] */ "/vercel/path0/debugger-app/src/components/ui/sidebar/sidebar-nav-items.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeToggle"] */ "/vercel/path0/debugger-app/src/components/ui/sidebar/theme-toggle.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["User"] */ "/vercel/path0/debugger-app/src/components/ui/sidebar/user.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/vercel/path0/debugger-app/src/components/ui/theme-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/debugger-app/src/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/debugger-app/src/static/profile.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/debugger-app/src/static/robot-profile.png");
