import { useInternalToolbarStore } from "./store";
import IdsToggleButton from "./IdsToggleButton";
const ErrorIdsToggleButton = () => {
  const {
    showErrorIds,
    setShowErrorIds
  } = useInternalToolbarStore();
  const handleToggle = () => {
    setShowErrorIds(!showErrorIds);
  };
  return <IdsToggleButton handleToggle={handleToggle} showIds={showErrorIds} label="Error" data-sentry-element="IdsToggleButton" data-sentry-component="ErrorIdsToggleButton" data-sentry-source-file="errorIdsToggleButton.tsx" />;
};
export default ErrorIdsToggleButton;