"use client";

import { Button } from "@/components/ui/button";
import Link from "next/link";
import { MiscIcons, PageIcons } from "@/components/icons/icons";
import { usePathname } from "next/navigation";
import { SidebarGroup, SidebarGroupContent, SidebarMenu, SidebarMenuItem, SidebarSeparator } from "../sidebar";
export function SidebarNavItems({
  isDemo,
  orgId,
  errorsFeatureEnabled
}: {
  isDemo: boolean;
  orgId: number | null;
  errorsFeatureEnabled: boolean;
}) {
  const pathName = usePathname();
  function getButtonVariant(neededPath: string): "default" | "ghost" {
    if (neededPath === "/errors") {
      return pathName === neededPath || pathName.includes("/issue/") ? "default" : "ghost";
    } else {
      return pathName.includes(neededPath) ? "default" : "ghost";
    }
  }
  return <div className="pt-2 ml-2" data-sentry-component="SidebarNavItems" data-sentry-source-file="sidebar-nav-items.tsx">
      <SidebarGroup className="p-0" data-sentry-element="SidebarGroup" data-sentry-source-file="sidebar-nav-items.tsx">
        <SidebarGroupContent className="w-68" data-sentry-element="SidebarGroupContent" data-sentry-source-file="sidebar-nav-items.tsx">
          <SidebarMenu data-sentry-element="SidebarMenu" data-sentry-source-file="sidebar-nav-items.tsx">
            <SidebarMenuItem data-sentry-element="SidebarMenuItem" data-sentry-source-file="sidebar-nav-items.tsx">
              <Link href="/issues" data-sentry-element="Link" data-sentry-source-file="sidebar-nav-items.tsx">
                <Button variant={getButtonVariant("/issues")} className="w-full justify-start" data-sentry-element="Button" data-sentry-source-file="sidebar-nav-items.tsx">
                  <PageIcons.Issues size={22} className="mr-3" data-sentry-element="unknown" data-sentry-source-file="sidebar-nav-items.tsx" /> Issues
                </Button>
              </Link>
            </SidebarMenuItem>
            <SidebarMenuItem data-sentry-element="SidebarMenuItem" data-sentry-source-file="sidebar-nav-items.tsx">
              <Link href="/replays" data-sentry-element="Link" data-sentry-source-file="sidebar-nav-items.tsx">
                <Button variant={getButtonVariant("/replays")} className="w-full justify-start" data-sentry-element="Button" data-sentry-source-file="sidebar-nav-items.tsx">
                  <PageIcons.AllReplays size={22} className="mr-3" data-sentry-element="unknown" data-sentry-source-file="sidebar-nav-items.tsx" />
                  All Replays
                </Button>
              </Link>
            </SidebarMenuItem>
            {isDemo && <SidebarMenuItem>
                <Link href="/behavior">
                  <Button variant={getButtonVariant("/behavior")} className="w-full justify-start">
                    <PageIcons.UserBehavior size={22} className="mr-3" />
                    User Behavior
                  </Button>
                </Link>
              </SidebarMenuItem>}
            {orgId && errorsFeatureEnabled && <SidebarMenuItem>
                <Link href="/errors">
                  <Button variant={getButtonVariant("/errors")} className="w-full justify-start">
                    <PageIcons.Errors size={22} className="mr-3" /> Errors
                  </Button>
                </Link>
              </SidebarMenuItem>}
            {isDemo && <SidebarMenuItem>
                <Link href="/reports">
                  <Button variant={getButtonVariant("/reports")} className="w-full justify-start">
                    <PageIcons.Reports size={22} className="mr-3" />
                    Reports
                  </Button>
                </Link>
              </SidebarMenuItem>}
            {isDemo && <SidebarMenuItem>
                <Link href="/insights">
                  <Button variant={getButtonVariant("/insights")} className="w-full justify-start">
                    <PageIcons.Insights size={22} className="mr-3" />
                    Insights
                  </Button>
                </Link>
              </SidebarMenuItem>}
          </SidebarMenu>
        </SidebarGroupContent>
      </SidebarGroup>
      <SidebarSeparator className="mx-0 w-68 my-3" data-sentry-element="SidebarSeparator" data-sentry-source-file="sidebar-nav-items.tsx" />
      <SidebarGroup className="p-0" data-sentry-element="SidebarGroup" data-sentry-source-file="sidebar-nav-items.tsx">
        <SidebarGroupContent className="w-68" data-sentry-element="SidebarGroupContent" data-sentry-source-file="sidebar-nav-items.tsx">
          <SidebarMenu data-sentry-element="SidebarMenu" data-sentry-source-file="sidebar-nav-items.tsx">
            <SidebarMenuItem data-sentry-element="SidebarMenuItem" data-sentry-source-file="sidebar-nav-items.tsx">
              <Link href="/alerts" data-sentry-element="Link" data-sentry-source-file="sidebar-nav-items.tsx">
                <Button variant={getButtonVariant("/alerts")} className="w-full justify-start" data-sentry-element="Button" data-sentry-source-file="sidebar-nav-items.tsx">
                  <PageIcons.Alerts size={22} className="mr-3" data-sentry-element="unknown" data-sentry-source-file="sidebar-nav-items.tsx" /> Alerts
                </Button>
              </Link>
            </SidebarMenuItem>
            <SidebarMenuItem data-sentry-element="SidebarMenuItem" data-sentry-source-file="sidebar-nav-items.tsx">
              <Link href="/settings" data-sentry-element="Link" data-sentry-source-file="sidebar-nav-items.tsx">
                <Button variant={getButtonVariant("/settings")} className="w-full justify-start" data-sentry-element="Button" data-sentry-source-file="sidebar-nav-items.tsx">
                  <PageIcons.OrganizationSettings size={22} className="mr-3" data-sentry-element="unknown" data-sentry-source-file="sidebar-nav-items.tsx" />
                  Organization Settings
                </Button>
              </Link>
            </SidebarMenuItem>

            {isDemo && <SidebarMenuItem>
                <Button variant={getButtonVariant("/billing")} className="w-full justify-start">
                  <PageIcons.Billing size={22} className="mr-3" /> Billing
                </Button>
              </SidebarMenuItem>}
          </SidebarMenu>
        </SidebarGroupContent>
      </SidebarGroup>
      <SidebarSeparator className="mx-0 w-68 my-3" data-sentry-element="SidebarSeparator" data-sentry-source-file="sidebar-nav-items.tsx" />
      <SidebarGroup className="p-0" data-sentry-element="SidebarGroup" data-sentry-source-file="sidebar-nav-items.tsx">
        <SidebarGroupContent className="w-68" data-sentry-element="SidebarGroupContent" data-sentry-source-file="sidebar-nav-items.tsx">
          <SidebarMenu data-sentry-element="SidebarMenu" data-sentry-source-file="sidebar-nav-items.tsx">
            <SidebarMenuItem data-sentry-element="SidebarMenuItem" data-sentry-source-file="sidebar-nav-items.tsx">
              <Link href="https://docs.getdecipher.com" target="_blank" data-sentry-element="Link" data-sentry-source-file="sidebar-nav-items.tsx">
                <Button variant={getButtonVariant("/docs")} className="w-full justify-start" data-sentry-element="Button" data-sentry-source-file="sidebar-nav-items.tsx">
                  <PageIcons.Docs size={22} className="mr-3" data-sentry-element="unknown" data-sentry-source-file="sidebar-nav-items.tsx" /> Docs <MiscIcons.ExternalLink size={16} className="ml-1" data-sentry-element="unknown" data-sentry-source-file="sidebar-nav-items.tsx" />
                </Button>
              </Link>
            </SidebarMenuItem>
          </SidebarMenu>
        </SidebarGroupContent>
      </SidebarGroup>
    </div>;
}