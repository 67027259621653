"use client";

import { PageIcons } from "@/components/icons/icons";
import { Command, CommandDialog, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command";
import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";
interface CommandMenuProps {
  isDemo: boolean;
  orgId: number | undefined;
  errorsFeatureEnabled: boolean;
}
export function CommandMenu({
  isDemo,
  orgId,
  errorsFeatureEnabled
}: CommandMenuProps) {
  const [open, setOpen] = useState(false);
  const router = useRouter();
  const suggestions = [{
    icon: PageIcons.Errors,
    label: "Errors",
    path: "/errors",
    showIf: orgId && errorsFeatureEnabled
  }, {
    icon: PageIcons.Issues,
    label: "Issues",
    path: "/issues"
  }, {
    icon: PageIcons.AllReplays,
    label: "All Replays",
    path: "/replays"
  }, {
    icon: PageIcons.Reports,
    label: "Reports",
    path: "/reports",
    showIf: isDemo
  }, {
    icon: PageIcons.Insights,
    label: "Insights",
    path: "/insights",
    showIf: isDemo
  }];
  const settings = [{
    icon: PageIcons.Alerts,
    label: "Alerts",
    path: "/alerts"
  }, {
    icon: PageIcons.OrganizationSettings,
    label: "Settings",
    path: "/settings"
  }
  // { icon: PiCreditCardThin, label: "Billing", path: "/billing" },
  ];
  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpen(open => !open);
      }
    };
    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, []);
  const runCommand = (command: () => void) => {
    setOpen(false);
    command();
  };
  return <CommandDialog open={open} onOpenChange={setOpen} data-sentry-element="CommandDialog" data-sentry-component="CommandMenu" data-sentry-source-file="CommandMenu.tsx">
      <Command className="rounded-lg shadow-md" data-sentry-element="Command" data-sentry-source-file="CommandMenu.tsx">
        <CommandInput placeholder="Type a command or search..." data-sentry-element="CommandInput" data-sentry-source-file="CommandMenu.tsx" />
        <CommandList data-sentry-element="CommandList" data-sentry-source-file="CommandMenu.tsx">
          <CommandEmpty data-sentry-element="CommandEmpty" data-sentry-source-file="CommandMenu.tsx">No results found.</CommandEmpty>
          <CommandGroup heading="Suggestions" data-sentry-element="CommandGroup" data-sentry-source-file="CommandMenu.tsx">
            {suggestions.filter(item => item.showIf === undefined || item.showIf).map(item => <CommandItem key={item.path} onSelect={() => runCommand(() => router.push(item.path))}>
                  <item.icon className="mr-2 h-4 w-4" />
                  <span>{item.label}</span>
                </CommandItem>)}
          </CommandGroup>
          <CommandGroup heading="Settings" data-sentry-element="CommandGroup" data-sentry-source-file="CommandMenu.tsx">
            {settings.map(item => <CommandItem key={item.path} onSelect={() => runCommand(() => router.push(item.path))}>
                <item.icon className="mr-2 h-4 w-4" />
                <span>{item.label}</span>
              </CommandItem>)}
          </CommandGroup>
        </CommandList>
      </Command>
    </CommandDialog>;
}