import { IconActivityHeartbeat, IconAmpersand, IconArrowDown, IconArrowRight, IconBackground, IconBellExclamation, IconBook, IconBrain, IconBrandYoutube, IconBuildings, IconBulb, IconClick, IconCreditCard, IconExternalLink, IconHandClick, IconHelp, IconList, IconMoonStars, IconNotesOff, IconReport, IconSearch, IconSettings, IconSparkles, IconSun, IconTarget, IconTelescope, IconUserStar, IconZoomExclamation } from "@tabler/icons-react";
export const PageIcons = {
  Issues: IconList,
  AllReplays: IconBrandYoutube,
  UserBehavior: IconActivityHeartbeat,
  Errors: IconZoomExclamation,
  Reports: IconReport,
  Insights: IconSparkles,
  Alerts: IconBellExclamation,
  OrganizationSettings: IconBuildings,
  Billing: IconCreditCard,
  Docs: IconBook
};
export const MiscIcons = {
  ExternalLink: IconExternalLink,
  Settings: IconSettings,
  ArrowDown: IconArrowDown,
  ArrowRight: IconArrowRight,
  Search: IconSearch,
  Lightbulb: IconBulb,
  NoResults: IconNotesOff,
  Telescope: IconTelescope,
  HelpCircle: IconHelp
};
export const EnrichedSessionIcons = {
  FeatureUsed: IconTarget,
  ClickTarget: IconClick,
  UserIntention: IconUserStar,
  ContextOfInteraction: IconBackground,
  UserAction: IconHandClick,
  InteractionAnalysis: IconBrain,
  ANDOperator: IconAmpersand
};
export const ThemeIcons = {
  DarkMode: IconMoonStars,
  LightMode: IconSun
};