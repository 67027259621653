import { useInternalToolbarStore } from "./store";
import IdsToggleButton from "./IdsToggleButton";
const ReplayIdsToggleButton = () => {
  const {
    showReplayIds,
    setShowReplayIds
  } = useInternalToolbarStore();
  const handleToggle = () => {
    setShowReplayIds(!showReplayIds);
  };
  return <IdsToggleButton handleToggle={handleToggle} showIds={showReplayIds} label="Replay" data-sentry-element="IdsToggleButton" data-sentry-component="ReplayIdsToggleButton" data-sentry-source-file="replaysIdsToggleButton.tsx" />;
};
export default ReplayIdsToggleButton;