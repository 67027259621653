"use client";

import { DemoAccessGate } from "@/components/DemoAccessGate";
import { useState, useEffect } from "react";
export default function Home() {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  useEffect(() => {
    // Check if user is already authenticated by looking for the cookie
    const authenticated = document.cookie.includes("demo-authenticated=true");
    setIsAuthenticated(authenticated);

    // If authenticated, redirect to the issues page immediately
    if (authenticated) {
      window.location.href = "/issues";
    }
  }, []);
  const handleCorrectPassword = () => {
    // When correct password is entered:
    // 1. Set authentication cookie (24 hour expiry)
    // 2. Update authentication state
    // 3. Redirect to issues page
    document.cookie = "demo-authenticated=true; path=/; max-age=86400";
    setIsAuthenticated(true);
    window.location.href = "/issues";
  };

  // Show nothing while initial authentication check is happening
  if (isAuthenticated === null) {
    return null;
  }

  // If not authenticated, show the password protection component
  if (!isAuthenticated) {
    return <DemoAccessGate onCorrectPassword={handleCorrectPassword} />;
  }

  // Return null during the brief moment between authentication and redirect
  return null;
}