import { create } from "zustand"

interface InternalToolbarStore {
  showReplayIds: boolean
  setShowReplayIds: (show: boolean) => void
  showSignatureId: boolean
  setShowSignatureId: (show: boolean) => void
  showErrorIds: boolean
  setShowErrorIds: (show: boolean) => void
  showAnalyzeReplayButton: boolean
  setShowAnalyzeReplayButton: (show: boolean) => void
}

export const useInternalToolbarStore = create<InternalToolbarStore>((set) => ({
  showReplayIds: false,
  setShowReplayIds: (show: boolean) => set({ showReplayIds: show }),
  showSignatureId: false,
  setShowSignatureId: (show: boolean) => set({ showSignatureId: show }),
  showErrorIds: false,
  setShowErrorIds: (show: boolean) => set({ showErrorIds: show }),
  showAnalyzeReplayButton: false,
  setShowAnalyzeReplayButton: (show: boolean) => set({ showAnalyzeReplayButton: show }),
}))
