"use client";

import { useTheme } from "next-themes";
import { dark } from "@clerk/themes";
import { UserButton } from "@clerk/nextjs";
import { useState, useEffect } from "react";
import { useUser } from "@clerk/nextjs";
import posthog from "posthog-js";
import * as Sentry from "@sentry/nextjs";
import LogRocket from 'logrocket';
export function User() {
  const {
    theme
  } = useTheme();
  const {
    user: clerkUser
  } = useUser();
  useEffect(() => {
    if (clerkUser?.id) {
      const email = clerkUser.emailAddresses[0].emailAddress;
      posthog.identify(clerkUser.id, {
        email,
        name: clerkUser.fullName
      });
      Sentry.setUser({
        id: clerkUser.id,
        email
      });
      LogRocket.identify(clerkUser.id!, {
        name: clerkUser.fullName!,
        email
      });
    }
  }, [clerkUser]);
  const clerkAppearance = theme === "dark" ? {
    baseTheme: dark
  } : {};
  return <div data-sentry-component="User" data-sentry-source-file="user.tsx">
      <div className="flex items-center">
        <span className="mr-2 truncate inline-block" style={{
        maxWidth: "157px"
      }}>
          {clerkUser?.fullName}
        </span>
        <UserButton afterSignOutUrl="/" showName={false} appearance={clerkAppearance} data-sentry-element="UserButton" data-sentry-source-file="user.tsx" />
      </div>
    </div>;
}